:root {
  --theme-color: #d2222b;
}

.schedule-cell-dimension.e-schedule .e-timeline-month-view .e-content-wrap table col,
		.schedule-cell-dimension.e-schedule .e-timeline-view .e-content-wrap table col {
			width: 80px;
		}

#centar{
    margin: 10px 50px 20px;
}

.today-bg {
    background-color: rgb(200, 200, 200) !important;
}
  

.resource-detail {
    float: left;
    margin-right: 2vh;
    margin-left: 1vh;
    white-space: nowrap;
    transition: all 0.3s ease;
}

.resource-detail:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.resource-name {
    font-weight: bold;
}

.resource-img {
    float: right;
}

.bijelo{
    float: right;
    color: white;
    width: 10%;
    font-size: large;
}
.velko{
    font-size: larger;
    line-height: 2;
}
.redovi{
  background-color: gray;
  display: flex;
  color: white;
  font-size: larger;
  justify-content: space-around;
  border-bottom: 2px solid var(--theme-color);
  border-left: 2px solid var(--theme-color);
  border-right: 2px solid var(--theme-color);
}
.ludnica{
  color: black;
  font-size: larger;
  
}
button[type=submit]{
  background-color: white;
}

.crnaludnica{
  color: white;
  background-color: black;
}




















.e-calendar-blue.e-input-group.e-control-wrapper.e-date-wrapper.e-input-focus::before,
.e-calendar-blue.e-input-group.e-control-wrapper.e-date-wrapper.e-input-focus::after,
.e-calendar-blue.e-input-group.e-control-wrapper.e-date-wrapper::before,
.e-calendar-blue.e-input-group.e-control-wrapper.e-date-wrapper::after,
.e-calendar-blue.e-input-group:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
.e-calendar-blue.e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
.e-calendar-blue.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-calendar-blue.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after {
  background: none;
  border: none;
  transition: none;
  color: white;
}
/* To change the selected day's and today's color*/

.e-calendar-blue .e-calendar .e-content td.e-selected span.e-day,
.e-calendar-blue .e-calendar .e-content td.e-today.e-selected span.e-day {
  background: blue;
  color: white;
}
.e-calendar-blue .e-calendar .e-content td.e-today span.e-day {
  color: blue;
  border: none;
  box-shadow: none;
}
/* To change the Today button and date icon color*/

.e-calendar-blue.e-date-wrapper
  span.e-input-group-icon.e-date-icon.e-icons.e-active,
.e-calendar-blue .e-btn.e-flat.e-primary,
.e-calendar-blue .e-css.e-btn.e-flat.e-primary {
  color: blue;
}
.e-calendar-blue .e-btn.e-flat.e-primary::hover {
  background: rgb(19 112 219 / 17%);
  color: blue;
}

.schedule-cell-dimension.e-schedule .e-timeline-view .e-resource-left-td,
.schedule-cell-dimension.e-schedule .e-timeline-month-view .e-resource-left-td{
  width: 35vh;
}





.custom-daterange  {
     width: 10%; 
    border: 2px solid var(--theme-color); 
    background-color: transparent; 
    color: black; 
    border-radius: 12px; 
    padding: 10px; 
    margin: 5px; 
}




.custom-schedule1 {
  border-top: 2px solid var(--theme-color) !important; 
  border-left: 2px solid var(--theme-color) !important;
  border-right: 2px solid var(--theme-color) !important;
  border-bottom: 2px solid var(--theme-color) !important;
}


.zaobljeno {
  border-radius: 20px !important; /* Zaobljeni uglovi */
  
}





.template-oblast {
  display: flex;
  justify-content: space-between; /* Razdvaja lijevi i desni sadržaj */
  align-items: center; /* Poravnava vertikalno */
  padding: 5px;
  border-radius: 5px;
  color: white;
  font-size: 12px;
}

.event-left {
  gap: 5px; /* Razmak između Subject i brojPonude */
  font-size: 14px;
}

.event-right {
  padding-left: 100%; /* Osigurava da se desni sadržaj poravna desno */
  
}


.e-schedule .e-timeline-view .e-resource-group-cells, 

.e-schedule .e-timeline-month-view .e-resource-group-cells,

.e-schedule .e-timeline-view .e-work-cells {

    border-right-width: 2px;

    border-style: solid;

}